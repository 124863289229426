<template>
    <div>
        <!-- NC cards list -->
        <v-card>
            <v-card-title>
                <h2>Mes évènements</h2>
            </v-card-title>

            <!-- NC list -->
            <!--
                NOTES:
                    - etatLabel and parentDepartement properties are added in the front end while loading the list
                    - computedHeaders is a computed function the returns the headers that can be seen depending if the user is on a mobile device or not
            -->
            <v-data-table
                :headers="computedHeaders"
                :items="listVisible"
                :rows-per-page-items="rowsPerPageText"
                v-bind:pagination.sync="pagination"
                @update:pagination="savePagination"
            >

                <template v-slot:headers="props">
                    <th v-for="(header, index) in props.headers" v-if="header.show === 'all' || (isMaintenance && header.show === 'maintenance') || (!isMaintenance && header.show === 'non-maintenance')">
                        {{header.text}}
                        <v-text-field
                            v-if="header.sortable !== false"
                            v-model="header.search"
                            @input="applyFilters"
                            clearable
                        >
                            <template v-slot:append-outer>
                                <v-icon
                                    small
                                    @click="setHeaderSort(index)"
                                >
                                    {{getHeaderSortIcon(header)}}
                                </v-icon>
                            </template>
                        </v-text-field>
                    </th>
                </template>
                <template v-slot:items="props">
                    <tr :key="props.item.id" :class="'gravite-' + props.item.gravite">
                        <td>
                            <PopUpImage
                                :popUpImageSrc="getImageSrc(props.item)"
                                width="100"
                                height="50"
                                :thumbnailSrc="getSmallThumbsnailSrc(props.item)"
                                :toRoute="{ name:'edit', params: {ncId: props.item.id}}"
                                :non-conformite="props.item"
                            />
                        </td>
                        <td>{{ props.item.projet || 'N/A' }}</td>
                        <td>{{ props.item.user?.fullName || 'N/A' }}</td>
                        <!-- NOTES:
                                - when using the advanced search there is no stageUnit property (we use the stageUnitId to create the property)
                                - when when not using the advanced search there is stageUnit property (we use the stageUnitId to affect a new value to the property)
                                - we have to do this because some NC have a stage unit id but the stage unit column is NULL
                        -->
                        <td>{{ props.item.stageUnit || 'N/A' }}</td>
                        <td>{{ getFormatedDate(props.item.createdAt) }}</td>
                        <td v-if="!$vuetify.breakpoint.smAndDown">{{ props.item.stageModel || 'N/A' }}</td>
                        <td v-if="!$vuetify.breakpoint.smAndDown">{{ props.item.of || 'N/A' }}</td>
                        <td v-if="!$vuetify.breakpoint.smAndDown">{{ props.item.etatLabel }}</td>
                        <!-- NC of type SAV dont need a departement so parent department is now nullable   -->
                        <td v-if="!isMaintenance">{{ !!props.item.parentDepartement ? props.item.parentDepartement.nom :  'N/A' }}</td>
                        <td v-if="!$vuetify.breakpoint.smAndDown && isMaintenance">{{ getMaintenanceGraviteLabels(props.item.gravite) }}</td>
                        <td>{{ props.item.titre }}</td>
                        <td>{{ getResponsableNc(props.item) }}</td>
                        <td><span :class="{'travailApprouver': props.item?.travailApprouveDate?.created_at }">{{ getCreateAtDate(props.item?.travailApprouveDate?.created_at) || 'N/A' }}</span></td>
                        <td>
                            <v-btn
                                flat
                                small
                                fab class="success"
                                :to="`/nc/edit/${props.item.id}`"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    //VUEX
    import { mapState, mapActions, mapGetters } from 'vuex';

    import { NON_CONFORMITES_STORE } from '../constants';
    import PopUpImage from './controls/PopUpImage';
    import filterMixin from "../../../../shared/vue_mixins/filterMixin";

    export default {
        components: { PopUpImage },
        props: ['listeNonConformites', 'isQualite', 'isMaintenance', 'isSAV'],
        mixins: [filterMixin],
        async created(){
            //DATA TABLES HEADERS
            let paramsFromRoute = false;
            //gets filter values from URL
            this.headers.forEach(header => {
                if (header.value != null && this.$route.query.hasOwnProperty('filter' + header.value)) {
                    paramsFromRoute = true;
                    header.search = this.$route.query['filter' + header.value];
                }
            });

            //gets filter values from local storage
            if (!paramsFromRoute) {
                this.headers.forEach(header => {
                    if (header.value != null) {
                        let memValue = localStorage.getItem('filter' + header.value);
                        if (memValue != null) {
                            header.search = memValue;
                        }
                    }
                });
            }
            this.applyFilters();

            //sets NC list data table pagination if saved in local storage
            //NOTE: if user selected to see all the rows on the same page, rowsPerPage value will be -1
            let rowsPerPage = localStorage.getItem('rowsPerPage');

            if(!!rowsPerPage) {
                this.pagination.rowsPerPage = parseInt(rowsPerPage);
            }

            //this.initializeHeaders(this.isMaintenance);
        },
        data(){
            return {
                list: [],
                listVisible: [],
                inputSearch: '',
                headers: [
                    {text: '', sortable: false, show:'all'},
                    {text: '#Projet', sortable: true, value: 'projet', show:'all'},
                    {text: 'Demandeur', sortable: true, value: 'user.fullName', show:'all'},
                    {text: 'Unité', sortable: true, value: 'stageUnit', show:'all'},
                    {text: 'Création', sortable: true, value: 'createdAt', show:'all'},
                    {text: 'Modele', sortable: true, value: 'stageModel', hide:'smAndDown', show:'all'},
                    {text: 'OF', sortable: true, value: 'of', hide:'smAndDown', show:'all'},
                    {text: 'État', sortable: true, value: 'etatLabel', hide:'smAndDown', show:'all'},
                    {text: 'Département', sortable: true, value: 'parentDepartement.nom', show:'non-maintenance'},
                    {text: 'Gravite', sortable: true, value: 'gravite', hide:'smAndDown', show: 'maintenance'},
                    {text: 'Titre', sortable: true, value: 'titre', show:'all'},
                    {text: 'Responsable', sortable: true, show:'all'},
                    {text: 'Approuvé le', sortable: true, value: 'travailApprouveDate.created_at', show:'all'},
                    {text: 'Actions', sortable: false, show:'all'},
                ],
                rechercheArchives: false,
                rowsPerPageText: [
                    15,30,{ "text": "$vuetify.dataIterator.rowsPerPageAll", "value": -1 }
                ],
                pagination: { 'sortBy': 'numeroNc', 'descending': true, 'rowsPerPage': 15 },
                //criticality Maintenance
                criticalityMaintenance: [
                    { value: 0, text: '0 - Manque d\'information' },
                    { value: 1, text: '1 - Ne peut pas avoir de show' },
                    { value: 2, text: '2 - Show avec restrictions' },
                    { value: 3, text: '3 - Show avec adaptations' },
                    { value: 4, text: '4 - Esthétique seulement' },
                    { value: 5, text: '5 - Pas d\'impact sur l\'évènement' },
                ],
            }
        },
        computed: {
            //VUEX
            ...mapGetters([
                //entreprise store
                'getDepartement',
                'getStageUnitName',
            ]),

            ...mapState({
                nonConformiteStore: state => state.nonConformiteStore,
                loginStore: state => state.loginStore
            }),
            computedHeaders () {
                return this.headers.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])
            }
        },
        methods: {
            //VUEX
            ...mapActions([
                //non conformite store
                'fetchListeNonConformites',
                'fetchParamsNonConformite',
            ]),

            //PAGINATION
            //saves the number of rows per page in the local storage
            //NOTE: if user selected to see all the rows on the same page, rowsPerPage value will be -1
            savePagination(pagination) {
                localStorage.setItem('rowsPerPage', pagination.rowsPerPage);
            },
            clearSearch() {
                this.inputSearch = null;
                this.research();
            },

            applyFilters() {
                //sets local storage with filter values
                this.headers.forEach(header => {
                    if (header.value != null) {
                        localStorage.setItem('filter' + header.value, header.search != null ? header.search : '');
                    }
                });
                this.listVisible = this.filterTableData(this.list, this.headers);
                //emits to set the printable list (list that we send to the print page) with the current filtered list
                this.$emit('setPrintableList', this.listVisible);
                this.$emit('filter', this.headers);
                this.$forceUpdate();
            },
            getResponsableNc(item){
                if (item.resolutionMembersList !== null) {
                    if(Array.isArray(item.resolutionMembersList)){
                        let membersOnly = item.resolutionMembersList.filter(m => m.type === 'member');
                        if (membersOnly && Array.isArray(membersOnly) && membersOnly.length > 0)
                        {
                            return membersOnly[0].nom;
                        }
                    }
                }
                return "N/A";
            },
            getHeaderSortIcon(header) {
                if (header.sort === 'asc') {
                    return 'fas fa-sort-amount-down-alt';
                }
                else if (header.sort === 'desc') {
                    return 'fas fa-sort-amount-up';
                }
                return 'fas fa-sort';
            },
            getEtatLabel(etat){
                return this.$store.getters[NON_CONFORMITES_STORE.getters.GET_ETAT_LABEL](etat);
            },

            getFormatedDate(date){
                return date.substring(0,10);
            },

            getImageSrc(item){
                let retval = null;
                if(item.imagesFolder !== null ){
                    if (item.imagesFolder.length > 0){
                        retval = item.imagesFolder[0].src;
                    } else {
                        retval = "https://gnc.groupestageline.com/files/non-conformites/no_photo.png";
                    }
                } else {
                    retval =  "https://gnc.groupestageline.com/files/non-conformites/no_photo.png";
                }
                return retval;
            },

            getSmallThumbsnailSrc(item){
                let retval = null;
                if(item.imagesFolder.length > 0){
                    if(item.imagesFolder[0].smThumbnail !== null) {
                        retval =  item.imagesFolder[0].smThumbnail.src;
                    } else {
                        retval =  "https://gnc.groupestageline.com/files/non-conformites/no_photo.png";
                    }
                } else {
                    retval =  "https://gnc.groupestageline.com/files/non-conformites/no_photo.png";
                }
                return retval;
            },

            getMaintenanceGraviteLabels(gravite) {
                let crit = this.criticalityMaintenance.find(c => c.value === gravite);
                if (crit != null) {
                    return crit.text;
                }
                return 'N/A';
            },
            getCreateAtDate(itemDate){
                if(itemDate){
                    let formatDate = itemDate.split(' ');
                    return formatDate[0];
                }
                return itemDate
            },
            setHeaderSort(myIndex) {
                this.headers = this.headers.map((header, index) => {
                    if (index === myIndex) {
                        if (header.sort == null) {
                            header.sort = 'asc';

                        }
                        else if (header.sort === 'asc') {
                            header.sort = 'desc';
                        }
                        else {
                            header.sort = null;
                        }
                    }
                    else {
                        header.sort = null;
                    }
                    return header;
                });
                let sortHeader = this.headers.find(header => header.sort != null);
                if (sortHeader != null) {
                    this.pagination.sortBy = sortHeader.value;
                    this.pagination.descending = sortHeader.sort === 'desc';
                }
                else {
                    this.pagination.sortBy = null;
                }
            },
        },
        watch: {
            //adds to listVisible a new list every times the list changes
            listeNonConformites(newVal, oldVal) {
                if(!!newVal) {
                    this.list = [];
                    this.listVisible = [];

                    this.list = newVal.concat(this.$store.getters[NON_CONFORMITES_STORE.getters.GET_LISTE_NON_CONFORMITES_ARCHIVES]);
                    this.list.forEach(item => {
                        item.etatLabel = this.getEtatLabel(item.etat);
                        item.parentDepartement = this.getDepartement(item.ncParentDepartementId);
                        //gets stage unit of NC
                        /*NOTES:
                            - when using the advanced search there is no stageUnit property (we use the stageUnitId to create the property)
                            - when when not using the advanced search there is stageUnit property (we use the stageUnitId to affect a new value to the property)
                            - we have to do this because some NC have a stage unit id but the stage unit column is NULL
                        * */
                        item.stageUnit = this.getStageUnitName(item.stageUnitId);
                    });
                    this.applyFilters();
                }
            },
            isMaintenance(newVal, oldVal) {
                //this.initializeHeaders(newVal);
            }
        },
    }
</script>

<style scoped>
.gravite-2{
    background-color: #fbff00;
    font-weight: bolder;
}
.gravite-3{
    color:white;
    background-color: #f13535;
    font-weight: bolder;
}
.gravite-3:hover{
    color:black;
}
.travailApprouver{
    background-color: #221f1f;
    color: #04ff04;
    padding: 6px;
    border-radius: 8%;
    border: 1px solid #04ff04;
    font-weight: bolder;
}
</style>
