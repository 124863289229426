<template>
    <v-expansion-panel v-model="advancedSearchPanel" class="my-3">
        <v-expansion-panel-content>
            <!-- HEADER -->
            <template v-slot:header>
                <div>
                    <h2>Recherche avancée</h2>

                    <v-layout
                        v-if="advancedSearchPanel === null"
                        row
                        wrap
                        class="mt-4"
                    >
                        <!-- type -->
                        <v-flex
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Type: </strong>{{advancedSearchParams.type}}</p>
                        </v-flex>

                        <!-- department -->
                        <v-flex
                            v-if="!!advancedSearchParams.departmentId"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Département: </strong>{{getDepartmentName()}}</p>
                        </v-flex>

                        <!-- sub department -->
                        <v-flex
                            v-if="!!advancedSearchParams.subDepartmentId"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Sous département: </strong>{{getSubDepartmentName()}}</p>
                        </v-flex>

                        <!-- stage models -->
                        <v-flex
                            v-if="Array.isArray(advancedSearchParams.stageModelIds) && advancedSearchParams.stageModelIds.length > 0"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Modèles de scène: </strong>{{getStageModelsName()}}</p>
                        </v-flex>

                        <!-- stage units -->
                        <v-flex
                            v-if="Array.isArray(advancedSearchParams.stageUnitIds) && advancedSearchParams.stageUnitIds.length > 0"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Unités de scène: </strong>{{getStageUnitsName()}}</p>
                        </v-flex>

                        <!-- states -->
                        <v-flex
                            v-if="Array.isArray(advancedSearchParams.states) && advancedSearchParams.states.length > 0"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>États: </strong>{{getStatesName()}}</p>
                        </v-flex>

                        <!-- Demandeurs -->
                        <v-flex
                            v-if="Array.isArray(advancedSearchParams.memberIds) && advancedSearchParams.memberIds.length > 0"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Demandeurs: </strong>{{getMembersName()}}</p>
                        </v-flex>

                        <!-- project number -->
                        <v-flex
                            v-if="!!advancedSearchParams.projectNumber"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Numéro de projet: </strong>{{advancedSearchParams.projectNumber}}</p>
                        </v-flex>

                        <!-- assembly number -->
                        <v-flex
                            v-if="!!advancedSearchParams.assemblyNumber"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Numéro d'assemblage: </strong>{{advancedSearchParams.assemblyNumber}}</p>
                        </v-flex>

                        <!-- creation date from -->
                        <v-flex
                            v-if="!!advancedSearchParams.creationDateFrom"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Date création (début): </strong>{{advancedSearchParams.creationDateFrom}}</p>
                        </v-flex>

                        <!-- creation date to -->
                        <v-flex
                            v-if="!!advancedSearchParams.creationDateTo"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Date création (fin): </strong>{{advancedSearchParams.creationDateTo}}</p>
                        </v-flex>

                        <!-- travail approuver date from -->
                        <v-flex
                            v-if="!!advancedSearchParams.approuverTravailDateFrom"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Date travail approuver (début): </strong>{{advancedSearchParams.approuverTravailDateFrom}}</p>
                        </v-flex>

                        <!-- travail approuver date to -->
                        <v-flex
                            v-if="!!advancedSearchParams.approuverTravailDateTo"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Date travail approuver (fin): </strong>{{advancedSearchParams.approuverTravailDateTo}}</p>
                        </v-flex>

                        <!-- keywords -->
                        <!-- NOTE: shows results from searches in title, description, proposed solution, alternative solution, proposed corrective action -->
                        <v-flex
                            v-if="!!advancedSearchParams.keyWords"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Mots-clés: </strong>{{advancedSearchParams.keyWords}}</p>
                        </v-flex>

                        <!-- NC numbers -->
                        <v-flex
                            v-if="Array.isArray(advancedSearchParams.ncNumbers) && advancedSearchParams.ncNumbers.length > 0"
                            xs3
                            class="mr-5"
                        >
                            <p><strong># NC: </strong>{{getNcNumbersString()}}</p>
                        </v-flex>

                        <!-- NC numbers -->
                        <v-flex
                            v-if="Array.isArray(advancedSearchParams.gravite) && advancedSearchParams.gravite.length > 0"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>Gravité: </strong>{{getGraviteList()}}</p>
                        </v-flex>

                        <!-- with required corrective action -->
                        <v-flex
                            v-if="!!advancedSearchParams.withRequiredCorrectiveAction"
                            xs3
                            class="mr-5"
                        >
                            <p><strong>NC avec action corrective requise seulement: </strong>Oui</p>
                        </v-flex>
                    </v-layout>
                </div>
            </template>

            <!-- BODY -->
            <v-card>
                <v-card-text>
                    <v-layout row wrap justify-center>
                        <!-- type -->
                        <v-flex xs3>
                            <v-select
                                v-model="advancedSearchParams.type"
                                label="Type"
                                :items="[
                                    { text: 'Qualité', value: 'qualite' },
                                    { text: 'Maintenance', value: 'maintenance' },
                                    { text: 'SAV', value: 'sav' },
                                ]"
                                @input="typeChange"
                            />
                        </v-flex>

                        <!-- department -->
                        <v-flex xs3 class="mx-5">
                            <v-select
                                v-model="advancedSearchParams.departmentId"
                                label="Département"
                                :items="departments"
                                item-text="nom"
                                item-value="id"
                                clearable
                                @input="emitSaveAdvancedSearchParams"
                            />
                        </v-flex>

                        <!-- sub department -->
                        <v-flex xs3>
                            <v-select
                                v-model="advancedSearchParams.subDepartmentId"
                                label="Sous-Département(s)"
                                :items="filteredSubDepartments"
                                item-text="nom"
                                item-value="id"
                                clearable
                                @input="emitSaveAdvancedSearchParams"
                            />
                        </v-flex>
                    </v-layout>

                    <v-layout row wrap justify-center>
                        <!-- stage model -->
                        <v-flex xs3>
                            <v-autocomplete
                                v-model="advancedSearchParams.stageModelIds"
                                label="Modèle de scène"
                                :items="stageModels"
                                item-value="id"
                                item-text="proposalModelName"
                                clearable
                                multiple
                                @input="emitSaveAdvancedSearchParams"
                            />
                        </v-flex>

                        <!-- stage unit -->
                        <v-flex xs3 class="mx-5">
                            <v-autocomplete
                                v-model="advancedSearchParams.stageUnitIds"
                                label="Unité de scène"
                                :items="stageUnits"
                                item-value="id"
                                item-text="unit"
                                clearable
                                multiple
                                @input="emitSaveAdvancedSearchParams"
                            />
                        </v-flex>

                        <!-- states -->
                        <v-flex xs3>
                            <v-select
                                v-model="advancedSearchParams.states"
                                label="États"
                                :items="states"
                                item-text="label_fr"
                                item-value="name"
                                clearable
                                multiple
                                @input="emitSaveAdvancedSearchParams"
                            />
                        </v-flex>
                    </v-layout>

                    <v-layout row wrap justify-center>
                        <!-- project number -->
                        <v-flex xs3>
                            <v-text-field
                                v-model="advancedSearchParams.projectNumber"
                                label="Numéro de projet"
                                clearable
                                @input="emitSaveAdvancedSearchParams"
                            />
                        </v-flex>

                        <!-- assembly number -->
                        <v-flex xs3 class="mx-5">
                            <v-text-field
                                v-model="advancedSearchParams.assemblyNumber"
                                label="Numéro d'assemblage"
                                clearable
                                @input="emitSaveAdvancedSearchParams"
                            />
                        </v-flex>

                        <!-- key words -->
                        <!-- NOTE: searches in title, description, proposed solution, alternative solution, proposed corrective action -->
                        <v-flex xs3>
                            <v-text-field
                                title="* Le champ mots-clés effectue une recherche titre, description, solution proposée, solution alternative et action corrective proposée"
                                v-model="advancedSearchParams.keyWords"
                                label="Mots-clés"
                                clearable
                                @input="emitSaveAdvancedSearchParams"
                            />
                        </v-flex>
                    </v-layout>

                    <v-layout row wrap justify-center>
                        <!-- Demandeurs -->
                        <v-flex xs3>
                            <v-autocomplete
                                v-model="advancedSearchParams.memberIds"
                                label="Demandeurs"
                                :items="users"
                                item-value="id"
                                item-text="fullName"
                                clearable
                                multiple
                                @input="emitSaveAdvancedSearchParams"
                            />
                        </v-flex>

                        <!-- nc number -->
                        <v-flex xs3 class="mx-5">
                            <v-text-field
                                :value="generateNcNumbersStringWithoutSpace()"
                                title="Vous devez séparer chaque numéro de NC avec une virgule (,)"
                                placeholder="# NC"
                                clearable
                                @input="generateNcNumbersArray"
                            />
                        </v-flex>

                        <!-- NC Gravite -->
                        <v-flex xs3>
                            <v-select
                                v-model="advancedSearchParams.gravite"
                                label="Gravité"
                                :items="graviteList"
                                item-text="label_fr"
                                item-value="id"
                                clearable
                                multiple
                                @input="emitSaveAdvancedSearchParams"
                            />
                        </v-flex>
                    </v-layout>

                    <v-layout row wrap justify-center>
                        <!-- creation date from -->
                        <v-flex xs3 style="text-align: center">
                            <v-dialog
                                ref="fromDatePickerDialog"
                                v-model="fromDatePickerDialog"
                                :return-value.sync="advancedSearchParams.creationDateFrom"
                                persistent
                                lazy
                                full-width
                                width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <strong class="subtitle">De: </strong>

                                    <v-text-field

                                        title="Date de début de la portée pour la recherche par date de création"
                                        v-model="advancedSearchParams.creationDateFrom"
                                        label="Date création (début)"
                                        readonly
                                        clearable
                                        prepend-inner-icon="event"
                                        v-on="on"
                                        class="d-inline-block ml-2"
                                        @click:clear="clearDates('from')"
                                    />
                                </template>

                                <v-date-picker
                                    v-model="advancedSearchParams.creationDateFrom"
                                    scrollable
                                    locale="fr-CA"
                                >
                                    <v-spacer/>

                                    <v-btn flat color="primary" @click="fromDatePickerDialog = false">Cancel</v-btn>
                                    <v-btn flat color="primary" @click="saveCreationDate(advancedSearchParams.creationDateFrom, 'fromDatePickerDialog')">OK</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-flex>

                        <!-- creation date to -->
                        <v-flex xs3 style="text-align: center; border-right:1px solid black;">
                            <v-dialog
                                ref="toDatePickerDialog"
                                v-model="toDatePickerDialog"
                                :return-value.sync="advancedSearchParams.creationDateTo"
                                persistent
                                lazy
                                full-width
                                width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <strong class="subtitle">À: </strong>

                                    <v-text-field
                                        title="Date de fin de la portée pour la recherche par date de création"
                                        v-model="advancedSearchParams.creationDateTo"
                                        label="Date création (fin)"
                                        readonly
                                        clearable
                                        prepend-inner-icon="event"
                                        v-on="on"
                                        class="d-inline-block ml-2"
                                        @click:clear="clearDates('to')"
                                    />
                                </template>

                                <v-date-picker
                                    v-model="advancedSearchParams.creationDateTo"
                                    scrollable
                                    locale="fr-CA"
                                >
                                    <v-spacer/>

                                    <v-btn flat color="primary" @click="toDatePickerDialog = false">Cancel</v-btn>
                                    <v-btn
                                        flat
                                        color="primary"
                                        @click="saveCreationDate(advancedSearchParams.creationDateTo, 'toDatePickerDialog')"
                                        :disabled="advancedSearchParams.creationDateFrom > advancedSearchParams.creationDateTo"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-flex>

                        <!-- Approuver Travail date from -->
                        <v-flex xs3 style="text-align: center; border-left:1px solid black;">
                            <v-dialog
                                ref="fromDateApprouverTravailPickerDialog"
                                v-model="fromDateApprouverTravailPickerDialog"
                                :return-value.sync="advancedSearchParams.approuverTravailDateFrom"
                                persistent
                                lazy
                                full-width
                                width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <strong class="subtitle">De: </strong>

                                    <v-text-field
                                        title="Date de début de la portée pour la recherche par date de travail approuvé"
                                        v-model="advancedSearchParams.approuverTravailDateFrom"
                                        label="Date travail approuvé (début)"
                                        readonly
                                        clearable
                                        prepend-inner-icon="event"
                                        v-on="on"
                                        class="d-inline-block ml-2"
                                        @click:clear="clearDates('fromApprouverTravail')"
                                    />
                                </template>

                                <v-date-picker
                                    v-model="advancedSearchParams.approuverTravailDateFrom"
                                    scrollable
                                    locale="fr-CA"
                                >
                                    <v-spacer/>

                                    <v-btn flat color="primary" @click="fromDateApprouverTravailPickerDialog = false">Cancel</v-btn>
                                    <v-btn flat color="primary" @click="saveCreationDate(advancedSearchParams.approuverTravailDateFrom, 'fromDateApprouverTravailPickerDialog')">OK</v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-flex>

                        <!-- Approuver Travail date to -->
                        <v-flex xs3 style="text-align: center">
                            <v-dialog
                                ref="toDateApprouverTravailPickerDialog"
                                v-model="toDateApprouverTravailPickerDialog"
                                :return-value.sync="advancedSearchParams.approuverTravailDateTo"
                                persistent
                                lazy
                                full-width
                                width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <strong class="subtitle">À: </strong>

                                    <v-text-field
                                        title="Date de fin de la portée pour la recherche par date de travail approuvé"
                                        v-model="advancedSearchParams.approuverTravailDateTo"
                                        label="Date travail approuvé (fin)"
                                        readonly
                                        clearable
                                        prepend-inner-icon="event"
                                        v-on="on"
                                        class="d-inline-block ml-2"
                                        @click:clear="clearDates('toApprouverTravail')"
                                    />
                                </template>

                                <v-date-picker
                                    v-model="advancedSearchParams.approuverTravailDateTo"
                                    scrollable
                                    locale="fr-CA"
                                >
                                    <v-spacer/>

                                    <v-btn flat color="primary" @click="toDateApprouverTravailPickerDialog = false">Cancel</v-btn>
                                    <v-btn
                                        flat
                                        color="primary"
                                        @click="saveCreationDate(advancedSearchParams.approuverTravailDateTo, 'toDateApprouverTravailPickerDialog')"
                                        :disabled="advancedSearchParams.approuverTravailDateFrom > advancedSearchParams.approuverTravailDateTo"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-flex>
                    </v-layout>

                    <v-layout row wrap justify-center>
                        <!-- with corrective action required -->
                        <v-flex xs3>
                            <v-checkbox
                                v-model="advancedSearchParams.withRequiredCorrectiveAction"
                                label="Afficher NC avec action corrective requise seulement"
                                @change="withRequiredCorrectiveActionChange"
                            />

                            <!-- required corrective action completion -->
                            <v-radio-group
                                v-if="(advancedSearchParams.type === 'qualite' || advancedSearchParams.type === 'maintenance') && advancedSearchParams.withRequiredCorrectiveAction"
                                v-model="advancedSearchParams.requiredCorrectiveActionState"
                                @change="emitSaveAdvancedSearchParams"
                            >
                                <v-radio
                                    name="state"
                                    label="Toute les actions correctives"
                                    :value="2"
                                />

                                <v-radio
                                    label="Actions correctives complétés"
                                    :value="1"
                                />

                                <v-radio
                                    label="Actions correctives non complétés"
                                    :value="0"
                                />
                            </v-radio-group>
                        </v-flex>
                    </v-layout>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />

                    <v-btn @click="clearAdvancedSearch">Tout effacer</v-btn>

                    <v-btn
                        class="primary ml-3"
                        @click="emitSearchWithAdvancedParams"
                    >
                        Rechercher
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    export default {
        name: "AdvancedSearch",
        props: [
            'advancedSearchParams',
            'departments',
            'stageModels',
            'stageUnits',
            'states',
            'users',
            'graviteList'
        ],
        data: function() {
            return {
                advancedSearchPanel: null,

                //NC numbers text fields v-model
                ncNumbers: '',

                //advanced search date picker dialogs v-model
                fromDatePickerDialog: false,
                toDatePickerDialog: false,
                fromDateApprouverTravailPickerDialog: false,
                toDateApprouverTravailPickerDialog: false,

                //advanced search sub departments
                filteredSubDepartments: []
            }
        },
        mounted(){
        },
        methods: {
            //emits search with advanced params
            emitSearchWithAdvancedParams() {
                let createDateFrom = this.advancedSearchParams.creationDateFrom;
                let createDateTo = this.advancedSearchParams.creationDateTo;
                let approuverDateFrom = this.advancedSearchParams.approuverTravailDateFrom;
                let approuverDateTo = this.advancedSearchParams.approuverTravailDateTo;

                if((createDateFrom.length > 0 && createDateTo.length === 0) || (createDateFrom.length === 0 && createDateTo.length > 0) ||
                    (approuverDateFrom.length > 0 && approuverDateTo.length === 0) || (approuverDateFrom.length === 0 && approuverDateTo.length > 0)){
                    alert("Si vous désirez chercher par date, vous devez absolument saisir une date de début et une date de fin.");
                }
                else{
                    //emits to seach with advanced search params
                    this.$emit('searchWithAdvancedParams');
                }
            },

            //generates an array with the NC numbers text fields value
            //NOTE:NC numbers needs to be separated with an comma (,)
            generateNcNumbersArray(ncNumbers) {
                //generates array and affects it to advanced search params
                if(!!ncNumbers) {
                    this.advancedSearchParams.ncNumbers = ncNumbers.split(',');
                }
                else {
                    this.advancedSearchParams.ncNumbers = [];
                }


                //emits save advanced search params
                this.emitSaveAdvancedSearchParams();
            },

            //generates a value for the NC numbers text field base on the NC numbers advanced search params
            generateNcNumbersStringWithoutSpace() {
                let ncNumbersList = '';

                for(let ncNumber of this.advancedSearchParams.ncNumbers) {
                    ncNumbersList += `${ncNumber},`;
                }

                return ncNumbersList.slice(0, -1);
            },

            //emits save advanced search params
            emitSaveAdvancedSearchParams() {
                this.$emit('saveAdvancedSearchParams');
            },

            //resets date pickers value when the clear icon is clicked
            clearDates(dateField) {
                if(dateField === 'from') {
                    this.advancedSearchParams.creationDateFrom = '';
                }
                else if(dateField === 'fromApprouverTravail'){
                    this.advancedSearchParams.approuverTravailDateFrom = '';
                }
                else if(dateField === 'toApprouverTravail'){
                    this.advancedSearchParams.approuverTravailDateTo = '';
                }
                else {
                    this.advancedSearchParams.creationDateTo = '';
                }

                this.emitSaveAdvancedSearchParams();
            },

            //clears all advanced search inputs
            clearAdvancedSearch() {
                this.advancedSearchParams.type = 'qualite';
                this.advancedSearchParams.departmentId = '';
                this.advancedSearchParams.subDepartmentId = '';
                this.advancedSearchParams.stageModelIds = [];
                this.advancedSearchParams.stageUnitIds = [];
                this.advancedSearchParams.states = [];
                this.advancedSearchParams.memberIds = [];
                this.advancedSearchParams.ncNumbers = [];
                this.advancedSearchParams.projectNumber = '';
                this.advancedSearchParams.assemblyNumber = '';
                this.advancedSearchParams.creationDateFrom = '';
                this.advancedSearchParams.creationDateTo = '';
                this.advancedSearchParams.approuverTravailDateFrom = '';
                this.advancedSearchParams.approuverTravailDateTo = '';
                this.advancedSearchParams.keyWords = '';
                this.advancedSearchParams.withRequiredCorrectiveAction = false;
                this.advancedSearchParams.requiredCorrectiveActionState = null;
                this.advancedSearchParams.gravite = [];

                this.emitSaveAdvancedSearchParams();
                this.$emit('refresh');
            },

            //gets sub departments of selected department
            getSubDepartments() {
                //gets department with the selected department id
                let selectedDepartment = this.departments.find(d => d.id ===  this.advancedSearchParams.departmentId);

                //if a selected department is found and it has sub departments
                if(!!selectedDepartment && !!selectedDepartment.sousDepartements && !!selectedDepartment.sousDepartements.length > 0) {
                    this.filteredSubDepartments = selectedDepartment.sousDepartements
                }
                else {
                    //sets sub departments to empty
                    this.filteredSubDepartments = [];

                    //resets sub departments id
                    this.advancedSearchParams.subDepartmentId = '';
                }

                this.emitSaveAdvancedSearchParams();
            },

            //gets department name
            getDepartmentName() {
                let department = this.departments.find(d => d.id ===  this.advancedSearchParams.departmentId);

                if(!!department) {
                    return department.nom;
                }
            },

            //gets list of selected stage models name
            getStageModelsName() {
                let stageModelsList = '';

                for(let stageModelId of this.advancedSearchParams.stageModelIds) {
                    let stageModel = this.stageModels.find(sm => sm.id === stageModelId);

                    if(!!stageModel) {
                        stageModelsList += `${stageModel.proposalModelName}, `;
                    }
                }

                return stageModelsList.slice(0, -2);
            },

            //gets list of selected stage units name
            getStageUnitsName() {
                let stageUnitsList = '';

                for(let stageUnitId of this.advancedSearchParams.stageUnitIds) {
                    let stageUnit = this.stageUnits.find(su => su.id === stageUnitId);

                    if(!!stageUnit) {
                        stageUnitsList += `${stageUnit.unit}, `;
                    }
                }

                return stageUnitsList.slice(0, -2);
            },

            //gets list of selected states name
            getStatesName() {
                let statesList = '';

                for(let stateName of this.advancedSearchParams.states) {
                    let state = this.states.find(s => s.name === stateName);

                    if(!!state) {
                        statesList += `${state.label_fr}, `;
                    }
                }

                return statesList.slice(0, -2);
            },

            getGraviteList() {
                let graviteChoiceList = '';

                for(let gravite of this.advancedSearchParams.gravite) {
                    graviteChoiceList += gravite+", ";
                }

                return graviteChoiceList.slice(0, -2);
            },

            //gets list of selected states name
            getNcNumbersString() {
                let ncNumbersList = '';

                for(let ncNumber of this.advancedSearchParams.ncNumbers) {
                    ncNumbersList += `${ncNumber}, `;
                }

                return ncNumbersList.slice(0, -2);
            },

            //gets list selected members name
            getMembersName() {
                let membersList = '';

                for(let memberId of this.advancedSearchParams.memberIds) {
                    let member = this.users.find(u => u.id === memberId);

                    if(!!member) {
                        membersList += `${member.fullName}, `;
                    }
                }

                return membersList.slice(0, -2);
            },

            //gets sub department name
            getSubDepartmentName() {
                let subDepartment = this.filteredSubDepartments.find(sd => sd.id === this.advancedSearchParams.subDepartmentId);

                if(!!subDepartment) {
                    return subDepartment.nom;
                }
            },

            //saves chosen creation date and emits save advanced search params (for both creation date from and creation date to)
            saveCreationDate(date, ref) {
                this.$refs[ref].save(date);
                this.emitSaveAdvancedSearchParams();
            },

            //type select menu input event listener
            typeChange(type) {
                //if selected type is not Qualité and the user wants only the NC with a required corrective action
                if(type !== 'qualite' && this.advancedSearchParams.withRequiredCorrectiveAction) {
                    this.advancedSearchParams.requiredCorrectiveActionState = 2;
                }

                //emits to save advanced search params
                this.emitSaveAdvancedSearchParams();
            },

            //withRequiredCorrectiveActionChange checkbox change event listener
            withRequiredCorrectiveActionChange(withRequiredCorrectiveAction) {
                //if user wants to see NC with a required corrective action only
                if(withRequiredCorrectiveAction) {
                    this.advancedSearchParams.requiredCorrectiveActionState = 2;
                }
                //else, if user does not want to see NC with a required corrective action only
                else {
                    this.advancedSearchParams.requiredCorrectiveActionState = null;
                }

                //emits to save advanced search params
                this.emitSaveAdvancedSearchParams();


            },
        },
        watch: {
            //sets sub departments list every times departmentId changes
            //NOTE: it is implemented this way so it can work when we apply saved advanced search params from URL or local storage
            'advancedSearchParams.departmentId': function(newVal, oldVal) {
                if(newVal) {
                    this.getSubDepartments();
                    this.advancedSearchParams.subDepartmentId = ''
                }
                else if(!newVal) {
                    this.advancedSearchParams.subDepartmentId = ''
                }
            },
        },
    }
</script>

<style scoped>

</style>
