<template>
    <v-snackbar
        :id="snackbarId"
        v-model="applicationStore.snackbar.visible"
        top
        :timeout="applicationStore.snackbar.timeout"
        :color="applicationStore.snackbar.color"
        class="my-2 custom-snackbar"
    >
        <span>{{ applicationStore.snackbar.text }}</span>
        <v-btn
            id="snackbar-close-btn"
            flat
            color="white"
            @click="fermerSnackbar"
        >Fermer</v-btn>
    </v-snackbar>
</template>


<script>
    import { mapState } from 'vuex';
    export default {
        name: "Snackbar.vue",
        computed: {
            ...mapState({
                applicationStore: state => state.applicationStore,
            }),
            snackbarId() {
                return `snackbar-${this.applicationStore.snackbar.type}`;
            }
        },
        data(){
            return {
            }
        },
        methods: {
            fermerSnackbar(){
                this.applicationStore.snackbar.visible = false;
            }
        }
    }
</script>

<style>
.custom-snackbar {
    position: absolute;
    z-index: 1000;
}

.v-snack__content  {
    overflow: visible;
    min-height: 80px;
    padding: 4px;
}
</style>
